
import { defineComponent, ref } from "vue";
import { QueryUserPaymasterArgs, User } from "@/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
type UserPaymasterData = {
  userPaymaster: User[];
};

const USER_PAYMASTER = gql`
  query UserPaymaster($activityId: Int!) {
    userPaymaster(activityId: $activityId) {
      id
      name
      email
    }
  }
`;
export default defineComponent({
  name: "TerminalUsers",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup() {
    const { loading, result } = useQuery<
      UserPaymasterData,
      QueryUserPaymasterArgs
    >(
      USER_PAYMASTER,
      { activityId: activeActivity.value.id },
      { fetchPolicy: "network-only" }
    );
    const options = useResult<UserPaymasterData, User[], User[]>(
      result,
      [],
      (res) => res.userPaymaster
    );
    return {
      loading,
      options,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card p-shadow-0" }
const _hoisted_2 = { class: "p-mb-0" }
const _hoisted_3 = {
  class: "p-mb-3",
  style: {"font-size":"12px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t("terminal.userSettings")), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("terminal.selectUsers")), 1),
    _createVNode(_component_DataTable, {
      value: _ctx.options,
      selection: _ctx.modelValue,
      responsiveLayout: "scroll",
      dataKey: "id",
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, { "selection-mode": "multiple" }),
        _createVNode(_component_Column, { header: "Tout cocher" }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.name || data.email), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "selection"])
  ]))
}